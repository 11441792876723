$global-margin: 15px;

$col-accent-1: #ff9900;
$col-dark-primary: #232f3e;

.glyphicon.fast-right-spinner {
  -moz-animation: glyphicon-spin-r 1s infinite linear;
  -webkit-animation: glyphicon-spin-r 1s infinite linear;
  animation: glyphicon-spin-r 1s infinite linear;
}

@-moz-keyframes glyphicon-spin-r {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@-webkit-keyframes glyphicon-spin-r {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes glyphicon-spin-r {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
