@import "./theme";
@import "~bootstrap";

.App {
  padding-top: $global-margin;
}

.navbar {
  margin-bottom: $global-margin;

  .userstate {
    font-size: 0.9em;
    padding: 15px;
    margin-right: 20px;
  }
}

// Stop navbar height getting compressed on small viewports (since we have big header):
.navbar-brand {
  height: auto;
}

.brand-glyphicon {
  font-size: 2em;
  color: lighten($col-dark-primary, 20%);
}

.navbar-brand-link {
  display: flex;
  flex-direction: row;
  align-items: center;

  .brand-name {
    display: block;
    font-weight: bold;
    color: $col-accent-1;
  }
  
  .brand-tagline {
    display: block;
    font-size: 0.8em;
  }
  
  .brand-img {
    padding-right: 10px;
  }
}

.navbar-toggle{
  margin-top: 20px;
}

.attribution-row {
  color: #777;
  padding-top: $global-margin * 4;
  padding-bottom: $global-margin;

  .citation {
    font-style: italic;
  }
}
