@import "../../theme";

.productDetail td {
  border: 0px;
  padding: 10px;
}
.productDetail span {
  text-align: justify;
}

.related-section {
  h3 {
    border-top: 1px solid $col-dark-primary;
    padding-top: 10px;
    color: $col-dark-primary;
  }
}
