@import "../../theme";

.similar {
  max-width: 100%;

  .testimonial-group {
    max-width: 100%;
  }
}

.similar span {
  vertical-align: top;
}

.itemsForUser {
  max-width: 100%;

  .testimonial-group {
    max-width: 100%;
  }
  padding-bottom: 20px;
}

.testimonial-group {
  >.row {
    overflow-x: auto;
    overflow: -moz-scrollbars-horizontal;
    white-space: nowrap;

    >.col-xs-12 {
      display: inline-block;
      float: none;
    }
  }
}

.filter-form {
  padding: $global-margin / 2;
  padding-top: 0px;
  margin-top: -$global-margin / 2;

  >* {
    margin: $global-margin/2;
  }

  .filter-param-text {
    width: 350px;
    max-width: 100%; // Not perfect, but close enough
  }
}
